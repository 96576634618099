import React, { useState } from "react";
import {
  FaFacebook,
  FaTripadvisor,
  FaEnvelope,
  FaPhone,
  FaRunning,
} from "react-icons/fa";
import { Button } from "../../globalStyles";
import ContactForm from "../ContactForm/ContactForm";
import ContactFormHan from "../ContactForm/ContactFormHan";

import { BtnStyle, MenuContainer } from "../../pages/HomePage/Home.elements";

import leesMenu01 from "../../images/leesMenu01.png";
import leesMenu02 from "../../images/leesMenu02.png";
import leesMenu03 from "../../images/leesMenu03.png";
import leesMenu04 from "../../images/leesMenu04.png";
import leesMenu05 from "../../images/leesMenu05.png";
import leesMenu06 from "../../images/leesMenu06.png";
import leesMenu07 from "../../images/leesMenu07.png";
import leesMenu08 from "../../images/leesMenu08.png";
import {
  FooterContainer,
  FooterSubscription,
  FooterSubHeading,
  FooterSubText,
  Email,
  SocialMediaWrap,
  SocialMedia,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIcoLink,
  SocialIcon,
} from "./Footer.elements";

const Footer = () => {
  const [mode, setMode] = useState(true);
  const [showForm, setShowForm] = useState();
  const [showMenu, setShowMenu] = useState();

  function formDisplay() {
    setMode(!mode);
    setShowForm(mode ? <ContactForm /> : null);
    return;
  }

  function formDisplayHan() {
    setMode(!mode);
    setShowForm(mode ? <ContactFormHan /> : null);
    return;
  }
  function menuDisplay() {
    console.log("menuDisplay clicked", mode)
    setMode(!mode);
    return setShowMenu(
      mode ? (
        <MenuContainer>
          {/* <img src={menu1} /> */}
          {/* <img src={menu2} /> */}
          {/* 전체메뉴판 주석처리 */}
            <img src={leesMenu01} />
            <img src={leesMenu02} />
            <img src={leesMenu03} />
            <img src={leesMenu04} />
            <img src={leesMenu05} />
            <img src={leesMenu06} />
            <img src={leesMenu07} />
            <img src={leesMenu08} />
        </MenuContainer>
      ) : null
    );
  }
  return (
    <FooterContainer>
      <BtnStyle>
        <Button fontBig primary onClick={menuDisplay}>
          MENU
        </Button>
      </BtnStyle>
      <div>{showMenu}</div>
      <FooterSubscription>
        <FooterSubHeading>
          We support your Event! Contact us via Email for further discussion.
        </FooterSubHeading>
        <FooterSubText>
          *If you have trouble when ordering, please call our restaurant for Catering + House Deliverymore information.
        </FooterSubText>
        <Email>
          <a href="tel: +12504927172">
            <Button fontBig primary>
              <FaPhone /> Call
            </Button>
          </a>
          <a>
            <Button fontBig primary onClick={formDisplay}>
              <FaEnvelope /> Email
            </Button>
          </a>
          <a href="https://www.skipthedishes.com/lees-overseas-delights">
            <Button fontBig primary>
              <FaRunning />
              Delivery
            </Button>
          </a>
        </Email>
        <div>{showForm}</div>
      </FooterSubscription>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="/">
            <SocialIcon />
            Lees Overseas
          </SocialLogo>
          <WebsiteRights><a onClick={formDisplayHan}>
              HHStudio©2023
          </a></WebsiteRights>
          <SocialIcons>
            <SocialIcoLink
              href="https://www.facebook.com/Leesoversea-105103668377169"
              target="_blank"
              aria-label="Facebook">
              <FaFacebook />
            </SocialIcoLink>
            <SocialIcoLink
              href="https://www.facebook.com/Leesoversea-105103668377169"
              target="_blank"
              aria-label="Tripadvisor">
              <FaTripadvisor />
            </SocialIcoLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>

    </FooterContainer>
  );
};

export default Footer;
